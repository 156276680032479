<template>
    <v-container fluid>
        <v-row>
            <v-col sm="4">
                <h1 class="title font-weight-light">
                    {{ isInApproval ? 'Approvals' : 'Data Manager' }} ({{ $format.int(pager.total) }})
                </h1>
            </v-col>
            <v-col sm="2">
                <v-btn color="primary" dark small right fab :loading="isBusy" @click="loadData">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
            <!-- <v-col sm="2">
                <v-menu v-model="filterMenu" bottom offset-y nudge-top="-10" nudge-left="300" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <span class="forStatus">
                            <v-chip label color="primary" text-color="white" v-on="on" class="mt-1"><v-icon left>mdi-filter-variant</v-icon>Filter</v-chip>
                        </span>
                    </template>
                    <v-card width="680">
                        <v-container>
                            <v-row class="border-bottom">
                                <v-col sm="5">
                                    <v-select ref="filterQuestion" label="Question" v-model="filterQuestion" :items="filterFields" item-text="text" item-value="value" autofocus>
                                    <template v-slot:item="{ item }">
                                        <v-icon class="mr-2">{{ item.inIcon }}</v-icon>{{ item.text }}
                                    </template>
                                    </v-select>
                                </v-col>
                                <v-col sm="1" class="d-flex flex-column justify-center">
                                    <v-menu open-on-hover bottom offset-y nudge-left="38">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" icon><v-icon>{{ searchAt.icon }}</v-icon></v-btn>
                                        </template>
                                        <v-list dense class="pl-2 pr-2">
                                            <v-list-item link class="ma-0 pa-0" v-for="item in searchAtOptions" :key="item.value" @click="onSearchAt(item)">
                                                <v-icon class="mr-2">{{ item.icon }}</v-icon>{{ item.text }}
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col sm="5">
                                    <component
                                        ref="filterValue"
                                        :is="filterValueControl"
                                        v-bind="$props"
                                        v-model="filterValue"
                                        clearable
                                        placeholder="Filter value"
                                        :hint="searchAtText"
                                        :error="filterValueError"
                                        v-on:enter="onFilterAdd"
                                        @change="onFilterValueChange"
                                        @focus="$event.target.select()"></component>
                                    <slot></slot>
                                </v-col>
                                <v-col sm="1" class="d-flex flex-column justify-center">
                                    <v-btn icon @click="onFilterAdd"><v-icon>mdi-plus</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-for="item in filters" :key="item._id" class="border-bottom">
                                <v-col sm="5" class="d-flex flex-column justify-center pt-0 pb-0">
                                    {{ item.Field }}
                                </v-col>
                                <v-col sm="1" class="d-flex flex-column justify-center pt-0 pb-0">
                                    <span><v-icon>{{ item.Oper.icon }}</v-icon></span>
                                </v-col>
                                <v-col sm="5" class="d-flex flex-column justify-center pt-0 pb-0">
                                    <span class="pa-2">{{ oneOrMoreDisplay(item.Value) }}</span>
                                </v-col>
                                <v-col sm="1" class="d-flex flex-column justify-center pt-0 pb-0">
                                    <v-btn icon @click="onFilterRemove(item)"><v-icon>mdi-window-close</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-card-actions>
                            <span v-if="isInApproval">Approval</span>
                            <v-btn-toggle v-if="isInApproval" v-model="approvalFilter" borderless color="primary" class="ml-2">
                                <v-btn small value="P">To Review</v-btn>
                                <v-btn small value="N">Rejected</v-btn>
                                <v-btn small value="Y">Approved</v-btn>
                            </v-btn-toggle>
                            <v-spacer></v-spacer>
                            <v-btn text @click="filterMenu = false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col>
                <v-row dense>
                    <v-col>
                        <v-select ref="survey"
                            v-model="surveyId"
                            :items="surveyOptions"
                            item-text="Name"
                            item-value="_id"
                            label="Form"
                            autofocus
                            hide-details
                            @change="onSurveyChange">
                            <template v-slot:prepend-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-text-field v-model="formFilter"
                                            label="Enter your filter criteria"
                                            clearable
                                            @click:clear="clearLookupsFilter"
                                            @keydown.enter.prevent="loadLookups">
                                            <template v-slot:append-outer>
                                                <v-btn class="mr-5" fab x-small @click="loadLookups">
                                                    <v-icon >mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                        <v-list-item-subtitle class="d-flex justify-center">
                                            <b>Only 20 items displayed at a time</b>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col dense>
                        <v-select ref="version"
                            v-model="surveyVersion"
                            :items="surveyVersions"
                            item-text="Version"
                            item-value="_id"
                            label="Version"
                            autofocus
                            hide-details
                            return-object
                            @change="onSurveyVersionChange">
                            <template v-slot:prepend-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-text-field v-model="versionFilter" type="number" label="Enter your filter criteria" clearable  @click:clear="clearVersionsFilter">
                                            <template v-slot:append-outer>
                                                <v-btn @click="loadVersions" fab x-small class="mr-5"><v-icon >mdi-magnify</v-icon></v-btn>
                                            </template>
                                        </v-text-field>
                                        <v-list-item-subtitle class="d-flex justify-center"><b>Only 20 items displayed at a time</b></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card >
                    <div class="el-clearfix pb-1 pr-2">
                        <div class="mt-1 float-left">
                            <v-pagination v-model="pager.page"
                                :length="pager.pages"
                                :total-visible="10"
                                @next="loadData"
                                @previous="loadData"
                                @input="loadData"
                                style="width:auto;">
                            </v-pagination>
                        </div>
                        <div class="mt-3 pl-2 float-right">
                            <v-numeric v-model="pager.page"
                                :maxlength="11"
                                label="Go to Page"
                                dense
                                placeholder="Page #"
                                :hint="`Max: ${$format.int(pager.pages)}`"
                                class="el-text-right"
                                style="max-width:90px;"
                                persistent-hint
                                @enter="loadData"
                                v-on:blur="loadData">
                            </v-numeric>
                        </div>
                        <div class="mt-3 pl-2 float-right">
                            <v-select v-model="pager.size"
                                :items="pageSizes"
                                label="Page Size"
                                dense
                                hide-details
                                style="max-width:80px;"
                                @change="loadData">
                            </v-select>
                        </div>
                        <div class="mt-2 float-right">
                            <!-- <v-btn text class="ml-2"><v-icon>mdi-table-eye</v-icon></v-btn> -->
                            <v-menu bottom left offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" text class="ml-2">
                                        <v-icon :color="headers.length - 1 === headers.filter(o => o._vis).length - 1 ? '' : 'warning'">mdi-table-eye</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense class="pl-2 pr-2">
                                    <v-list-item>
                                        <v-btn color="primary" elevation="2" x-small @click="onHeaderVisAll">
                                            All
                                        </v-btn>
                                        &nbsp;
                                        <v-btn color="primary" elevation="2" x-small @click="onHeaderVisNone">
                                            None
                                        </v-btn>
                                    </v-list-item>
                                    <v-list-item v-for="(item, index) in headers.filter(o => o.value !== 'ApprovedIcon')" :key="index" dense link class="ma-0 pa-0 mh-30">
                                        <v-checkbox v-model="item._vis" :label="item.text" dense hide-details class="ma-0 pa-0" @change="onHeaderVisChange" />
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="mt-1 float-right">
                            <v-checkbox v-model="fieldNames" label="Field Names" hide-details @change="onFieldNamesChange" />
                        </div>
                    </div>
                    <!-- <div class="d-flex ml-2 mr-2 mb-2">
                        <span class="caption mr-2" style="margin-top:2px;">Filters</span>
                        <v-chip v-if="isInApproval" small color="info" class="mr-1" label>Approval <v-icon small class="ml-1" color="yellow">mdi-equal</v-icon><strong class="ml-1">{{ approvalFilter === 'P' ? 'Pending' : (approvalFilter === 'N' ? 'Rejected' : 'Approved') }}</strong></v-chip>
                        <v-chip v-if="!isInApproval && !filters.length" small color="grey lighten-3" class="mr-1" label>None</v-chip>
                        <v-chip v-for="item in filters" :key="item._id" small color="info" class="mr-1" label close @click:close="onFilterRemove(item)">
                            {{ item.Field }} <v-icon small class="ml-1" color="yellow">{{ item.Oper.icon }}</v-icon><strong class="ml-1">{{ oneOrMoreDisplay(item.Value) }}</strong>
                        </v-chip>
                    </div> -->
                    <div style="display: inline-block; width: 100%;">
                        <div style="width: 50%; float:left">
                            <v-filter :filterList="filters"
                                :surveyFilterFields="filterFields"
                                class="d-flex ml-2 mr-2 mb-2"
                                @removed="loadData"
                                @closed="loadData">
                            </v-filter>
                        </div>
                        <div style="width: 50%; float:right; text-align: right; padding-right: 5px;">
                            <v-menu v-if="selected.length > 0" bottom left offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn color="error" v-on="on" small><v-icon>mdi-delete-outline</v-icon>Bulk Delete</v-btn>
                                </template>
                                <v-btn color="error" @click="onBulkDelete">
                                    <v-icon class="mr-2">mdi-alert-decagram</v-icon>Are you sure?
                                </v-btn>
                            </v-menu>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div v-if="pager.total > 0">
                        <div class="wide-table">
                            <v-data-table v-model="selected"
                                :headers="headers.filter(o => o._vis)"
                                :items="tableData"
                                item-key="_id"
                                :items-per-page.sync="tableOptions.itemsPerPage"
                                :page.sync="tableOptions.page"
                                :sort-by.sync="tableOptions.sortBy"
                                :sort-desc.sync="tableOptions.sortDesc"
                                show-select
                                single-select
                                :loading="isBusy"
                                multi-sort
                                hide-default-footer
                                dense
                                no-data-text="No data.">
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>
                                            <v-checkbox v-model="selected" :value="item._id" style="margin:0px;padding:0px" hide-details />
                                        </td>
                                        <td v-if="isInApproval" class="ma-0 pa-0">
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" :color="item.Approved === 'P' ? 'gray' : (item.Approved === 'N' ? 'error' : 'success')">{{ item.ApprovedIcon }}</v-icon>
                                                </template>
                                                <span>{{ item.Reason || 'n/a' }} <i>[by: {{ item.Approver || '?' }}]</i></span>
                                            </v-tooltip>
                                        </td>
                                        <td v-for="header in headers.filter(o => o._vis && o.value !== 'ApprovedIcon')"
                                            :key="header.value"
                                            :class="`el-table-top pt-1 pb-1 ${header.align === 'start' ? 'text-left' : 'text-right'}`">
                                            <div v-if="!headerSkipList.has(header.type)"
                                                v-html="getValueForCell(item[header.value], header)"
                                                @click="onSelect(item)">
                                            </div>
                                            <div v-else-if="header.type === 'Image'">
                                                <v-lazy-image :input-url="item[header.value]"
                                                    :input-width="imgWidth"
                                                    :emitClick="true"
                                                    @click="onSelect(item)">
                                                </v-lazy-image>
                                            </div>
                                            <div v-else-if="header.type === 'ImageArray'">
                                                <v-lazy-image-array-vertical :input-field="header.value"
                                                    :input-urls="item[header.value]"
                                                    :input-width="imgWidth"
                                                    :emitClick="true"
                                                    @click="onSelect(item)">
                                                </v-lazy-image-array-vertical>
                                            </div>
                                            <div v-else-if="header.type === 'FileUpload' && uploadIsImage(header.UploadType)">
                                                <v-lazy-image :input-url="item[header.value].Value"
                                                    :input-width="imgWidth"
                                                    :emitClick="true"
                                                    @click="onSelect(item)">
                                                </v-lazy-image>
                                            </div>
                                            <div v-else-if="header.type === 'FileUpload' && !uploadIsImage(header.UploadType)">
                                                <v-lazy-upload :input="item[header.value]"></v-lazy-upload>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                        <v-divider></v-divider>
                        <div class="el-clearfix pb-1 pr-2">
                            <div class="mt-2 el-text-center">
                                <v-menu bottom offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-if="isInApproval" v-on="on" text class="ml-2" :disabled="!selected.length" :loading="isActioning">
                                            <v-icon class="mr-2">mdi-format-list-checks</v-icon>Action
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item link @click="onActionApprove">
                                            Approve
                                        </v-list-item>
                                        <v-list-item link @click="onActionReject">
                                            Reject
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-btn v-if="isInApproval" text class="ml-2" @click="onBulkFile">
                                    <v-icon class="mr-2">mdi-list-box-outline</v-icon>Bulk File
                                </v-btn>
                                <v-menu open-on-hover bottom offset-y nudge-top="-4">
                                    <template v-slot:activator="{ on }">
                                        <span class="forStatus">
                                            <v-btn v-on="on" text class="ml-2">
                                                <v-icon class="mr-2">mdi-export</v-icon>Export
                                            </v-btn>
                                        </span>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="item in exportOptions" :key="item.id" @click="onExportClick(item.id)">
                                            <v-list-item-title>
                                                <v-icon left>{{ item.icon }}</v-icon>{{ item.text }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                        <v-divider></v-divider>
                        <div class="el-clearfix pb-1 pr-2" >
                            <div class="mt-1 float-left">
                                <v-pagination v-model="pager.page"
                                    :length="pager.pages"
                                    :total-visible="10"
                                    @next="loadData"
                                    @previous="loadData"
                                    @input="loadData"
                                    style="width:auto;">
                                </v-pagination>
                            </div>
                            <div class="mt-3 pl-2 float-right">
                                <v-numeric v-model="pager.page"
                                    :maxlength="11"
                                    label="Go to Page"
                                    dense
                                    placeholder="Page #"
                                    :hint="`Max: ${$format.int(pager.pages)}`"
                                    class="el-text-right"
                                    style="max-width:90px;"
                                    persistent-hint
                                    @enter="loadData"
                                    v-on:blur="loadData">
                                </v-numeric>
                            </div>
                            <div class="mt-3 pl-2 float-right">
                                <v-select v-model="pager.size"
                                    :items="pageSizes"
                                    label="Page Size"
                                    dense
                                    hide-details
                                    style="max-width:80px;"
                                    @change="loadData">
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <v-card>
                            <v-card-title class="d-flex justify-center">No data available</v-card-title>
                        </v-card>
                    </div>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
import Util from '@/util/Util';
import numeric from '@/controls/Numeric';
import filterFilter from '@/controls/filter/Filter';
import { mapState } from 'vuex';
import ImageTools from '@/util/ImageTools';
import LazyImg from '@/controls/LazyImg.vue';
import LazyImgArrVertical from '@/controls/LazyImgArrVertical.vue';
import LazyUpload from '@/controls/LazyUpload.vue';

export default {
    name: 'DataManager',
    components: {
        'v-numeric': numeric,
        'v-filter': filterFilter,
        'v-lazy-image': LazyImg,
        'v-lazy-image-array-vertical': LazyImgArrVertical,
        'v-lazy-upload': LazyUpload
    },
    data: () => ({
        versionFilter: '',
        formFilter: '',
        isInApproval: false,
        isBusy: false,
        isLoading: false,
        isActioning: false,
        surveyId: null,
        surveyOptions: [],
        surveyVersion: null,
        surveyVersions: [],
        editLang: 'en', // English is the first language for this deployment.
        tagReg: /<\/?[^>]+(>|$)/g, // Remove tags from string.
        tableOptions: {
            sortBy: ['_UD'],
            sortDesc: [true],
            page: 1,
            itemsPerPage: 20
        },
        rawHeaders: [],
        headers: [
            { text: '_UD', align: 'start', value: 'Date' }
        ],
        headerVis: null,
        tableData: [],
        selected: [],
        fieldNames: false,
        pageSizes: Constants.PAGE_SIZE_5,
        pager: {
            size: 20,
            page: 1,
            pages: 1,
            total: 0
        },
        filterFields: [],
        filters: [],
        reasons: [],
        userOptions: [],
        searchAt: {},
        searchAtText: '',
        searchAtOptions: Constants.SEARCH_STRING,
        approvalOptions: [
            { _id: 'P', Value: 'To Review' },
            { _id: 'N', Value: 'Rejected' },
            { _id: 'Y', Value: 'Approved' },
        ],
        approvalFilter: 'P',
        options: {
            ProjectId: null,
            SurveyId: null,
            // StartDate: new Date().toISOString().substring(0, 10),
            // EndDate: new Date().toISOString().substring(0, 10),
            // Techs: [],
            Tags: [],
            Where: {},
            paging: null
        },
        exportOptions: [
            { id: 'tab', icon: 'mdi-alpha-t-box-outline', text: 'Tab Separated Values (tsv)' },
            { id: 'semi', icon: 'mdi-alpha-s-box-outline', text: 'Semicolon Separated Values (csv)' },
            { id: 'comma', icon: 'mdi-comma-box-outline', text: 'Comma Separated Values (csv)' },
            { id: 'xlsx', icon: 'mdi-file-excel-box-outline', text: 'Microsoft Excel (xlsx)' },
            { id: 'pdf', icon: 'mdi-alpha-p-box-outline', text: 'Acrobat PDF (pdf)' },
        ],
        ImageFileData: {},
        imageTools: new ImageTools(),
        isPermission: [],
        imgWidth: 200,
        headerSkipList: new Set(['Image', 'ImageArray', 'FileUpload'])
    }),
    mounted () {
        this.isPermission = this.$CONST.PERMISSION;

        const pageSize = this.$ls.get(Constants.LS_KEY.PAGE_SIZE);
        if (pageSize) this.pager.size = parseInt(pageSize, 10);

        this.fieldNames = this.$ls.get(Constants.LS_KEY.FIELDS) === 1;
        this.isInApproval = this.$route.name === 'ApprovalList';

        this.loadViewOptions();
        this.loadLookups(true).then(() => {
            this.init();
        });
    },
    methods: {
        async init () {
            await this.loadData();
        },
        async onBulkDelete () {
            const res = await this.$http.post(`/Survey/${this.surveyId}/bulkanswers`, { selected: this.selected }, this.authHeader);
            if (res.data !== undefined) {
                if (res.data.d.status === true) {
                    this.$info('Removed', `Removed ${res.data.d.records} RECORDS from the system.`, 2);
                    await this.loadData();
                }
                else {
                    this.$error(this.$t('general.data_failed'), 'Could not delete data from syste. Contact your administrator');
                }
            }
            else {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        clearLookupsFilter () {
            this.formFilter = null;
            this.loadLookups();
        },
        clearVersionsFilter () {
            this.versionFilter = null;
            this.loadVersions();
        },
        uploadIsImage (uploadType) {
            return uploadType === Constants.UPLOAD_FILE_TYPE.Images;
        },
        async loadLookups (initialLoad = false) {
            this.surveyOptions = [];
            if (!this.viewProject._id) return;

            this.isBusy = true;
            this.$showProgress();
            try {
                this.options.ProjectId = this.viewProject._id;

                // Multi column sorting uses a different pager object. Sync the two.
                const whereClause = {
                    ProjectId: this.viewProject._id,
                    StatusId: 'A',
                    $or: [
                        { PublishedVer: { $exists: true } },
                        { PublishedVer: { $gt: 0 } }
                    ]
                };
                if (this.formFilter) {
                    whereClause.Name = { $regex: `.*${this.formFilter}.*`, $options: 'i' };
                }
                this.tableOptions.page = this.pager.page;
                this.tableOptions.itemsPerPage = this.pager.size;
                const res = await this.$http.get('/Survey', { params: { where: whereClause, fields: 'Name Version TaskForm' } });
                if (res.data.d.length === 0) {
                    this.$info('Data Load', 'There was no data available for your search criteria.');
                }
                else {
                    this.surveyOptions = res.data.d;
                    this.surveyOptions = this.surveyOptions.filter(o => o.TaskForm !== true);// Filter out the task forms to ensure they do not display
                    this.surveyId = this.$ls.get(Constants.LS_KEY.SURVEY);
                    if (this.surveyId) {
                        // Make sure the id is for the selected project.
                        const survey = this.surveyOptions.find(o => o._id === this.surveyId);
                        if (!survey) this.surveyId = this.surveyOptions[0]._id;
                    }
                    else {
                        if (this.surveyOptions[0] !== undefined) {
                            this.surveyId = this.surveyOptions[0]._id;
                        }
                        else {
                            this.surveyId = '';
                        }
                    }
                    this.searchAt = this.searchAtOptions.find(o => o.value === 'any');
                    this.searchAtText = this.searchAt.text;

                    // Users.
                    const resu = await this.$http.get(`/User/fullnames/${encodeURI(this.viewProject._id)}`);
                    if (resu.data.s && resu.data.d) {
                        this.userOptions = resu.data.d;
                        for (const user of this.userOptions) { // Adjust for common selects.
                            user.Value = user.FullName;
                            delete user.FullName;
                        }
                    }

                    await this.loadVersions();
                    await this.setFilterOptions();
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
                if (!initialLoad) await this.loadData();
            }
        },
        async loadVersions () {
            this.surveyVersion = null;
            this.surveyVersions = [];
            if (!this.viewProject._id) return;
            this.isBusy = true;
            this.$showProgress();
            try {
                const whereClause = {
                    ProjectId: this.viewProject._id,
                    SurveyId: this.surveyId
                };
                if (this.versionFilter) {
                    whereClause.Version = Number(this.versionFilter);
                }

                const res = await this.$http.get('/SurveyPublished', { params: { where: whereClause, fields: 'Name Version', sort: { Version: -1 } } });
                const survey = this.surveyOptions.find(o => o._id === this.surveyId);

                if (res.data.d) {
                    // get the highest published version as the current selected survey might not have a version higher than the latest published
                    if (!this.versionFilter) {
                        survey.Version = res.data.d[0].Version;
                        this.surveyVersions.push(survey);
                    }

                    this.surveyVersion = survey;

                    for (let index = 0; index < res.data.d.length; index++) {
                        const element = res.data.d[index];
                        if (element.Version !== survey.Version) {
                            this.surveyVersions.push(element);
                        }
                    }
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        async loadData () {
            if (!this.viewProject._id) return;
            if (!this.surveyId) return;
            if (this.isLoading) return; // Dont execute again if it is busy executing

            this.isBusy = true;
            this.isLoading = true;
            this.$showProgress();
            try {
                this.options.ProjectId = this.viewProject._id;
                this.options.SurveyId = this.surveyId;
                this.options.paging = this.pager;
                this.options.Where = {};
                if (this.surveyVersion) {
                    this.options.Where = { _V: this.surveyVersion.Version };
                }
                Util.buildQueryWithFilters(this.options.Where, this.filters);
                this.options.Table = this.tableOptions;
                // Remember the page size.
                this.$ls.set(Constants.LS_KEY.PAGE_SIZE, this.pager.size);
                // Query the data.
                const res = await this.$http.post('Survey/answer', this.options);
                if (!res || !res.data || !res.data.s || !res.data.d) {
                    const err = (res.data.m) ? res.data.m : this.$t('general.an_error');
                    this.$error(this.$t('general.data_failed'), err);
                    return;
                }

                const d = res.data;
                this.selected = [];

                // Data.
                if (d.d) {
                    this.reasons = d.d.reasons;
                    this.rawHeaders = d.d.headers;
                    this.tableData = d.d.data;
                    for (const rec of this.tableData) {
                        rec.ApprovedIcon = rec.Approved === 'P'
                            ? 'mdi-progress-question'
                            : (rec.Approved === 'N' ? 'mdi-minus-circle' : 'mdi-check-circle');
                    }
                    await this.loadTable(true);
                }
                else {
                    this.headers = [{ text: 'Data', align: 'start', value: 'Data' }];
                    this.tableData = [];
                }

                // Pager.
                if (d.p) {
                    this.pager = d.p;
                }
                else {
                    this.pager = {
                        size: 20,
                        page: 1,
                        pages: 1,
                        total: 0
                    };
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), ex.message ? ex.message : this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.isLoading = false;
                this.$hideProgress();
            }
        },
        async loadTable (storeOptions) {
            if (storeOptions && !this.headerVis) {
                this.headerVis = {};
                this.rawHeaders.forEach(o => {
                    this.headerVis[o.Field] = true;
                });
                this.headerVis.ApprovedIcon = true;
                this.headerVis.Version = true;
                this.headerVis.Start = true;
                this.headerVis.End = true;
                this.headerVis.User = true;
            }

            const headers = [];
            if (this.isInApproval) {
                headers.push({ text: '', align: 'end', value: 'ApprovedIcon', width: 30, type: 'Icon', itype: Constants.INPUT_TYPE.Note, Iter: '', class: 'ma-0 pa-0', _vis: true }); // this.getHeaderVisibility('ApprovedIcon')
                headers.push({ text: `${this.makeShort('Approval')}`, align: 'start', value: 'Approved', width: 100, type: 'String', itype: Constants.INPUT_TYPE.SelectOne, options: this.approvalOptions, Iter: null, _vis: this.getHeaderVisibility('Approval'), removable: false });
            }

            for (const o of this.rawHeaders) {
                const header = {
                    text: `${o.Index}. ${this.getHeaderText(o)}`,
                    align: o.Type === 'Number' ? 'end' : 'start',
                    value: o.Field,
                    width: this.getWidthByType(o),
                    type: o.Type,
                    itype: o.InputType,
                    Iter: o.Iter,
                    _vis: this.getHeaderVisibility(o.Field)
                };

                if (o.Type === 'FileUpload') {
                    for (const rec of this.tableData) {
                        header.UploadType = o.UploadType;
                        if (this.uploadIsImage(header.UploadType)) {
                            const fieldId = rec._id + o.Field;
                            if (rec[o.Field].filename) {
                                this.ImageFileData[fieldId] = rec[o.Field].Value;
                            }
                            else {
                                this.ImageFileData[fieldId] = Constants.NO_IMAGE;
                            }
                        }
                    }
                }

                headers.push(header);
            }

            // System columns.
            headers.push({ text: `${this.makeShort('Version')}`, align: 'start', value: '_V', width: 100, type: 'Number', itype: Constants.INPUT_TYPE.Number, Iter: null, _vis: this.getHeaderVisibility('_V') });
            headers.push({ text: `${this.makeShort('Start')}`, align: 'start', value: '_SD', width: this.getWidthByType({ Type: 'DateTime' }), type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_SD') });
            headers.push({ text: `${this.makeShort('End')}`, align: 'start', value: '_ED', width: this.getWidthByType({ Type: 'DateTime' }), type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_ED') });
            headers.push({ text: `${this.makeShort('User')}`, align: 'start', value: '_UN', width: 200, type: 'String', itype: Constants.INPUT_TYPE.SelectMany, Iter: null, _vis: this.getHeaderVisibility('_UN') });
            headers.push({ text: 'UID', align: 'start', value: '_id', width: 100, type: 'String', itype: Constants.INPUT_TYPE.Text, Iter: '', _vis: this.getHeaderVisibility('_id') });
            this.headers = headers;

            // Store some options for when coming back to this view.
            if (storeOptions) {
                this.storeViewOptions();
            }
        },
        async setFilterOptions () {
            if (!this.surveyVersion) return;
            this.filters.splice(0, this.filters.length);
            // Prepare the survey questions for the filter.
            const whereClause = {
                ProjectId: this.viewProject._id,
                SurveyId: this.surveyId,
                Version: this.surveyVersion.Version
            };
            const res = await this.$http.get('/SurveyPublished', { params: { where: whereClause, fields: 'Name Version Questions Approval', sort: { Version: -1 } } });

            if (res.data.d && res.data.d.length > 0) {
                const headers = [];
                const survey = res.data.d[0];

                if (survey.Approval) {
                    headers.push({ text: `${this.makeShort('Approval')}`, align: 'start', value: 'Approved', width: 100, type: 'String', itype: Constants.INPUT_TYPE.SelectOne, options: this.approvalOptions, Iter: null, _vis: this.getHeaderVisibility('Approval'), removable: false });
                    this.filters.push({ _id: Date.now(), Field: 'Approved', Value: (this.isInApproval ? 'P' : 'Y'), Oper: Data.duplicate(Constants.SEARCH_STRING.find(o => o.text === 'Exact')), IType: Constants.INPUT_TYPE.SelectOne, Removable: false });
                }

                for (const o of survey.Questions) {
                    // Options for when the input has additional settings.
                    let options;
                    if (o.InputType === Constants.INPUT_TYPE.SelectOne || o.InputType === Constants.INPUT_TYPE.SelectMany) {
                        options = o.Options.filter(x => x.Lang === this.editLang && x.Value);
                    }
                    // const showField = req.body.fields && req.body.fields.indexOf(q.Field) > -1;
                    const showField = true;
                    o.Question = o.Question[0].Value; // TODO: Language.
                    o.Iter = o.LoopParentIndex !== undefined;
                    o.Type = Data.inputDataType(o.InputType, Constants.INPUT_TYPE);
                    o.Show = o.Rep_Show === false ? showField : false;
                    headers.push({
                        text: `${o.Index}. ${this.getHeaderText(o)}`,
                        align: o.Type === 'Number' ? 'end' : 'start',
                        value: o.Field,
                        width: 100,
                        type: o.Type,
                        itype: o.InputType,
                        options,
                        Iter: o.Iter,
                        _vis: this.getHeaderVisibility(o.Field)
                    });
                }

                // System columns.
                headers.push({ text: `${this.makeShort('Version')}`, align: 'start', value: '_V', width: 100, type: 'Number', itype: Constants.INPUT_TYPE.Number, Iter: null, _vis: this.getHeaderVisibility('_V') });
                headers.push({ text: `${this.makeShort('Start')}`, align: 'start', value: '_SD', width: 100, type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_SD') });
                headers.push({ text: `${this.makeShort('End')}`, align: 'start', value: '_ED', width: 100, type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_ED') });
                headers.push({ text: `${this.makeShort('User')}`, align: 'start', value: '_UN', width: 200, type: 'String', itype: Constants.INPUT_TYPE.SelectMany, options: this.userOptions, Iter: null, _vis: this.getHeaderVisibility('_UN') });
                headers.push({ text: 'UID', align: 'start', value: '_id', width: 100, type: 'String', itype: Constants.INPUT_TYPE.Text, Iter: '', _vis: this.getHeaderVisibility('_id') });
                this.filterFields = headers;
            }
        },
        storeViewOptions () {
            this.$ls.set(`DataMan_Options_${this.surveyId}`, JSON.stringify({
                approvalFilter: this.approvalFilter,
                pager: this.pager,
                tableOptions: this.tableOptions,
                headerVis: this.headerVis
            }));
        },
        loadViewOptions () {
            // Clear the options.
            this.approvalFilter = 'P'; // Pending.
            this.pager = {
                size: 20,
                page: 1,
                pages: 1,
                total: 0
            };
            this.headerVis = null;
            // Load the saved state.
            const options = this.$ls.get(`DataMan_Options_${this.surveyId}`);
            if (options) {
                try {
                    const opt = JSON.parse(options);
                    this.approvalFilter = opt.approvalFilter;
                    this.pager = opt.pager;
                    this.tableOptions = opt.tableOptions;
                    this.headerVis = opt.headerVis;
                }
                catch (ex) { }
            }
        },
        onHeaderVisAll () {
            this.headerVis = {};
            this.headers.forEach(o => {
                o._vis = true;
                this.headerVis[o.value] = o._vis;
            });
            this.storeViewOptions();
        },
        onHeaderVisNone () {
            this.headerVis = {};
            this.headers.forEach(o => {
                o._vis = false;
                this.headerVis[o.value] = o._vis;
            });
            this.storeViewOptions();
        },
        onHeaderVisChange () {
            this.headerVis = {};
            this.headers.forEach(o => {
                this.headerVis[o.value] = o._vis;
            });
            this.storeViewOptions();
        },
        getHeaderVisibility (field) {
            if (!this.headerVis || this.headerVis[field] === undefined) return true;
            return this.headerVis[field];
        },
        getHeaderText (q) {
            if (this.fieldNames) return q.Rep_Field || q.Field;
            else return this.makeShort(q.Question);
        },
        makeShort (str) {
            const len = str.length;
            const s = str.replace(this.tagReg, '').substr(0, 20);
            return (len > 20 && s.length === 20) ? `${s}...` : s;
        },
        onFieldNamesChange () {
            this.$ls.set(Constants.LS_KEY.FIELDS, this.fieldNames ? 1 : 0);
            this.loadTable();
        },
        onActionApprove () {
            this.$confirm({ title: 'Approve?', message: 'Are you sure you want to approve these records?' }, async yes => {
                if (!yes) return;
                this.approve();
            });
        },
        onActionReject () {
            const reasons = this.reasons.filter(o => o.Lang === this.editLang).map(o => o.Value);
            if (reasons.length) {
                this.$prompt({
                    title: 'Rejection Reason?',
                    message: 'Please provide the reason for rejecting these records:',
                    value: reasons[0],
                    options: reasons,
                    okText: this.$t('general.reject')
                }, async pres => {
                    if (!pres.Action) return;
                    this.reject(pres.Value);
                });
            }
            else {
                this.$confirm({ title: 'Reject?', message: 'Are you sure you want to reject these records?' }, async yes => {
                    if (!yes) return;
                    this.reject('None');
                });
            }
        },
        async approve () {
            this.isActioning = true;
            try {
                const res = await this.$http.post(`/Survey/${this.surveyId}/answer/bulk-approve`, {
                    Field: '_id',
                    Uids: this.selected.map(o => o._id),
                });
                if (res.data.s) {
                    this.$success('Success', 'The records were successfully flagged as approved.');
                    this.loadData();
                }
                else {
                    this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                }
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isActioning = false;
            }
        },
        async reject (reason) {
            this.isActioning = true;
            try {
                const res = await this.$http.post(`/Survey/${this.surveyId}/answer/bulk-reject`, {
                    Field: '_id',
                    Uids: this.selected.map(o => o._id),
                    Reason: reason
                });
                if (res.data.s) {
                    this.$success('Success', 'The records were successfully flagged as rejected.');
                    this.loadData();
                }
                else {
                    this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                }
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isActioning = false;
            }
        },
        getWidthByType (header) {
            return 'auto';
        },
        getValueForCell (value, header) {
            if (this.headerSkipList.has(header.type)) return '';
            if (header.Iter) return this.getValueForCellIter(value, header);
            if (value === null || value === undefined) return '';
            let returnString = '<ul>';

            switch (header.type) {
                case 'String':
                    return value;
                case 'Number':
                    return this.$format.number(value, { decimals: Number.isInteger(value) ? 0 : 2 });
                case 'Boolean':
                    return this.$format.yesNo(value);
                case 'Date':
                    return typeof value === 'string' ? (value.length > 10 ? this.$format.dateTimeCustom(new Date(value)) : value) : this.$format.dateTimeCustom(value);
                case 'Time':
                    return value;
                case 'Array':
                    return Data.arrayToBullet(value);
                case 'Location':
                    if (value !== '') {
                        return this.$format.gps(value[1], value[0], this.viewProject.GPS);
                    }
                    break;
                case 'XorGrid':
                    for (let i = 0; i < value.length; i++) {
                        const val = (value[i].Value === true) ? 'Yes' : 'No';
                        returnString += '<li>' + value[i].Question + ' => ' + val + '</li>';
                    }
                    returnString += '</ul>';
                    return returnString;
                case 'DriverQRScanner':
                    for (let i = 0; i < value.length; i++) {
                        const driver = value[i];
                        for (const key in driver) {
                            if (Object.prototype.hasOwnProperty.call(driver, key)) {
                                const val = driver[key];
                                returnString += `<li><strong>${key} => </strong> ${val}</li>`;
                            }
                        }
                    }
                    returnString += '</ul>';
                    return returnString;
                case 'UserLookup':
                    for (let i = 0; i < value.length; i++) {
                        const usr = value[i];
                        returnString += `<li>${usr.UserFullName}</li>`;
                    }
                    returnString += '</ul>';
                    return returnString;
                default:
                    return value;
            }
        },
        getValueForCellIter (values, header) {
            if (values === undefined || values === null) return '';
            if (!Array.isArray(values)) values = [values];
            const vals = ['<ol>'];
            values.forEach(value => {
                vals.push('<li>');
                switch (header.type) {
                    case 'String':
                        vals.push(value);
                        break;
                    case 'Number':
                        vals.push(this.$format.number(value, { decimals: Number.isInteger(value) ? 0 : 2 }));
                        break;
                    case 'Boolean':
                        vals.push(this.$format.yesNo(value));
                        break;
                    case 'Date':
                        vals.push(value);
                        break;
                    case 'Time':
                        vals.push(value);
                        break;
                    case 'Array':
                        vals.push(Data.arrayToBullet(value));
                        break;
                    case 'Location':
                        vals.push(this.$format.gps(value[1], value[0], this.viewProject.GPS));
                        break;
                    default:
                        vals.push(value);
                        break;
                }
                vals.push('</li>');
            });
            vals.push('</ol>');
            return vals.join('');
        },
        async onSurveyChange () {
            // Store the survey id.
            this.$ls.set(Constants.LS_KEY.SURVEY, this.surveyId);
            // Load the viewing options for the selected survey.
            // this.loadViewOptions();
            this.headers = [];
            // this.headerVis = {};
            this.selected = [];
            this.reasons = [];
            this.rawHeaders = [];
            this.tableData = [];
            this.setFilterOptions();
            await this.loadVersions();
            // load the data.
            this.loadData();
        },
        onSurveyVersionChange () {
            // load the data.
            this.loadData();
        },
        onSelect (item) {
            if (this.user.has([this.isPermission.DATA_MANAGER_EDIT])) {
                const survey = this.surveyOptions.find(o => o._id === this.surveyId);
                const routeQuery = {
                    id: item._id,
                    surveyId: this.surveyId,
                    surveyVersion: item._V,
                    surveyName: survey ? survey.Name : '?',
                    isApproval: this.isInApproval
                };
                this.$router.push({
                    name: 'DataEdit',
                    query: routeQuery
                });
            }
        },
        toggleSearchAt () {
            let pos = this.searchAtOptions.indexOf(this.searchAt);
            pos += 1;
            if (pos === this.searchAtOptions.length) pos = 0;
            this.searchAt = this.searchAtOptions[pos];
            this.searchAtText = this.searchAt.text;

            this.adjustFilterValueInput();

            this.$refs.filterValue.focus();
        },
        onSearchAt (item) {
            this.searchAt = item;
            this.searchAtText = this.searchAt.text;
            this.adjustFilterValueInput();
            this.$refs.filterValue.focus();
        },
        oneOrMoreDisplay (value) {
            return Array.isArray(value) ? Util.oneAndMore(value) : value;
        },
        onBulkFile () {
            const survey = this.surveyOptions.find(o => o._id === this.surveyId);
            this.$router.push({
                name: 'BulkFile',
                query: {
                    surveyId: this.surveyId,
                    surveyName: survey ? survey.Name : '?',
                    isApproval: this.isInApproval
                }
            });
        },
        async onExportClick (id) {
            try {
                // this.$info('Coming Soon', 'This feature is being worked on and will be available soon.', 5);
                this.options.ExportAll = 'All';
                const options = Data.duplicate(this.options);
                options.exportAs = id;
                const res = await this.$http.post('/Survey/answer-report', options);
                if (!res || !res.data || !res.data.s) {
                    const err = (res.data.m) ? res.data.m : this.$t('general.an_error');
                    this.$error(this.$t('general.data_failed'), err);
                    return;
                }

                this.$success('Submitted', 'Your export has been queued for generation and will be available in the Downloads section, once complete.', 3);
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), ex.message ? ex.message : this.$t('general.an_error'));
            }
        }
    },
    watch: {
        tableOptions: {
            handler () {
                this.loadData();
            },
            deep: true,
        },
        viewProject () {
            this.loadLookups();
        },
        filterMenu () {
            // Reload the data when the menu closes and reset the inputs.
            if (!this.filterMenu) {
                this.filterValueType = 'Dummy';
                this.adjustFilterValueInput();
                this.loadData();
            }
        },
        filterQuestion () {
            const field = this.filterFields.find(o => o.value === this.filterQuestion);
            this.filterValueType = field.type;

            // Set the match options for the type.
            switch (this.filterValueType) {
                case 'String':
                case 'Array':
                    this.searchAtOptions = Constants.SEARCH_STRING;
                    break;
                case 'Number':
                case 'Date':
                case 'DateTime':
                case 'Time':
                    this.searchAtOptions = Constants.MATCH_MATH;
                    break;
                case 'Boolean':
                    this.searchAtOptions = Constants.MATCH_BOOL;
                    break;
                case 'Image':
                case 'Location':
                default:
                    this.searchAtOptions = Constants.SEARCH_STRING;
                    break;
            }
            // Set the default.
            this.searchAt = this.searchAtOptions[0];
            this.searchAtText = this.searchAt.text;

            this.adjustFilterValueInput();

            if (this.$refs.filterValue && this.$refs.filterValue.focus) {
                this.$refs.filterValue.focus();
            }
        }
    },
    computed: {
        ...mapState({
            viewProject: 'viewProject',
            user: 'user'
        })
    }
};

</script>
