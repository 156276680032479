<template>
    <div @click="handleClick">
        <img v-if="imgUri" :src="imgUri" :width="imgWidth" style="max-width:500px;"/>
    </div>
</template>

<script>
import Constants from '@/util/Constants';

export default {
    name: 'LazyImg',
    props: {
        inputUrl: {
            type: String,
            default: null
        },
        inputWidth: {
            type: Number,
            default: 200
        },
        emitClick: {
            type: Boolean,
            default: false
        }
    },
    emits: ['open-dataedit'],
    data () {
        return {
            imgUri: null,
            imgWidth: this.inputWidth || 200
        };
    },
    mounted () {
        this.fetchImage();
    },
    methods: {
        fetchImage () {
            if (!this.inputUrl) {
                this.imgUri = Constants.NO_IMAGE;
                return;
            }

            this.$http.request({ url: this.inputUrl, method: 'GET', responseType: 'blob' })
                .then(res => {
                    if (res && res.data && `${res.data.type}`.includes('image/')) {
                        this.imgUri = URL.createObjectURL(res.data);
                    }
                    else {
                        this.imgUri = Constants.NO_IMAGE;
                    }
                })
                .catch(() => {
                    this.imgUri = Constants.NO_IMAGE;
                });
        },
        handleClick () {
            if (this.emitClick) this.$emit('click');
        }
    }
};
</script>
