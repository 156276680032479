<template>
    <div>
        <v-row small>
            <v-col cols="2">
                <v-btn color="success" @click="loadData">Get Details</v-btn>
            </v-col>
        </v-row>
        <br/>
        <v-expansion-panels v-if="reportData.length > 0">
            <v-expansion-panel v-for="o in reportData" :key="o._id">
                <v-expansion-panel-header>
                    Step - {{ reportData.indexOf(o)+1 }} : {{o.FormAnswer.FormName}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-card  :elevation="2" class="d-flex flex-column ml-2 mr-2 mt-0 mb-2">
                    <div class="f-b ml-2 el-text-truncate">
                        Captured by : {{o.FormAnswer.UserName}}
                    </div>
                    <div class="f-s ml-2 mr-2 opa-5">
                        {{o.FormAnswer.Date}}<span class="float-right">{{o.FormAnswer.Version}}</span>
                    </div>
                    <v-divider></v-divider>
                    <v-card-text class="flex-grow-1 pa-0">
                        <v-container fluid class="pt-0">
                            <div v-for="item in o.FormAnswer.Values.filter(x => x.Value !== null && x.Value !== undefined && x.Value !== '' && x.Title !== 'Record ID')" :key="item.Field">
                                <v-row dense class="mt-2">
                                    <v-col sm="12" class="pt-0">
                                        <div class="f-s" style="line-height:1;" v-html="item.Title"></div>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0" dense>
                                    <v-col sm="12" class="pt-0">
                                        <div class="body-1">
                                            <v-container v-if="item.Type === 'ImageArray'" fluid grid-list-md style="padding: 4px 0px;">
                                                <v-lazy-image-array-horizontal :input-field="item.Field"
                                                    :input-urls="item.Value"
                                                    :input-width="imgWidth">
                                                </v-lazy-image-array-horizontal>
                                            </v-container>

                                            <v-container v-else-if="item.Type === 'Image' || item.Type === 'Signature' || item.Type === 'Sketch'" fluid grid-list-md style="padding: 4px 0px;">
                                                <v-layout row wrap>
                                                    <v-flex>
                                                        <v-lazy-image :input-url="item.Value" :input-width="imgWidth"></v-lazy-image>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>

                                            <v-container v-else-if="item.Type === 'FileUpload' && uploadIsImage(item.Value)" fluid grid-list-md style="padding: 4px 0px;">
                                                <v-layout row wrap>
                                                    <v-flex>
                                                        <v-lazy-image :input-url="item.Value.Value" :input-width="imgWidth"></v-lazy-image>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>

                                            <v-container v-else-if="item.Type === 'FileUpload' && !uploadIsImage(item.Value)" fluid grid-list-md style="padding: 4px 0px;">
                                                <v-lazy-upload :input="item.Value"></v-lazy-upload>
                                            </v-container>

                                            <v-simple-table v-else-if="item.Type === 'XorGrid' && Array.isArray(item.Value)">
                                                <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">
                                                            <b>Question</b>
                                                        </th>
                                                        <th class="text-left" style="width: 150px">
                                                            <b>Answer</b>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="xor in item.Value" :key="xor._id">
                                                        <td class="text-left">
                                                            {{ xor.Question }}
                                                        </td>
                                                        <td class="text-left">
                                                            {{ (xor.Value)? 'Yes': 'No' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </template>
                                            </v-simple-table>

                                            <v-simple-table v-else-if="item.Type === 'DriverQRScanner' && Array.isArray(item.Value)">
                                                <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 26%">
                                                            <b>Driver ID</b>
                                                        </th>
                                                        <th class="text-left" style="width: 37%">
                                                            <b>Driver Name</b>
                                                        </th>
                                                        <th class="text-left">
                                                            <b>Driver Surname</b>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="dvr in item.Value" :key="dvr._id">
                                                        <td class="text-left">
                                                            {{ dvr.DriverId }}
                                                        </td>
                                                        <td class="text-left">
                                                            {{ dvr.DriverName }}
                                                        </td>
                                                        <td class="text-left">
                                                            {{ dvr.DriverSurname }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </template>
                                            </v-simple-table>

                                            <v-container v-else-if="item.Type === 'UserLookup' && Array.isArray(item.Value)" fluid grid-list-md style="padding: 4px 0px;">
                                                <div v-for="usr in item.Value" :key="usr._id">
                                                    {{usr.UserFullName}}
                                                </div>
                                            </v-container>

                                            <span v-else v-html="getItemValue(item.Value)" style="padding: 4px 0px;"></span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                    </v-card-text>
                </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div v-else>
            No Additional Task Data Loaded or Available
        </div>
        <v-spacer></v-spacer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LazyImg from './LazyImg.vue';
import LazyUpload from './LazyUpload.vue';
import LazyImgArrHorizontal from './LazyImgArrHorizontal.vue';

export default {
    name: 'RecordPreview',
    props: {
        propSubmissionIds: {
            default: null,
            type: Array
        }
    },
    components: {
        'v-lazy-image': LazyImg,
        'v-lazy-upload': LazyUpload,
        'v-lazy-image-array-horizontal': LazyImgArrHorizontal,
    },
    data () {
        return {
            reportData: [],
            isBusy: false,
            imgWidth: 200
        };
    },
    created () {},
    mounted () {
        // if (this.propSubmissionId) this.loadData();
    },
    methods: {
        async loadData () {
            if (this.propSubmissionIds === null) return;
            this.isBusy = true;
            this.$showProgress();
            const whereClause = {
                'FormAnswer._id': { $in: this.propSubmissionIds }
            };
            try {
                const res = await this.$http.get('/Submission', { params: { where: whereClause } });
                if (res.data.d.length === 0) {
                    this.$info('Data Load', 'There is no submission available.');
                    this.reportData = [];
                }
                else {
                    this.reportData = res.data.d;
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        getItemValue (val) {
            if (val && Array.isArray(val)) {
                return val.join(' | ');
            }

            const valStr = val.toString();
            if (valStr === 'TRUE' || valStr === 'true') return 'Yes';
            if (valStr === 'FALSE' || valStr === 'false') return 'No';

            if (typeof val === 'string') return val.replaceAll('\n', '<br />');

            return val;
        },
    },
    computed: {
        ...mapState({
            user: 'user',
            viewProject: 'viewProject'
        })
    }
};
</script>

<style lang="scss" scoped>
</style>
