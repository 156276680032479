<template>
    <v-layout row wrap>
        <v-flex v-for="(img, i) in imgUris" :key="`${imgField}_${i}`" :style="imageStyle">
            <img :src="img" :width="imgWidth" class="imgFit"/>
        </v-flex>
    </v-layout>
</template>

<script>
import Constants from '@/util/Constants';

export default {
    name: 'LazyImgArrHorizontal',
    props: {
        inputField: {
            type: String,
            default: ''
        },
        inputUrls: {
            type: [Array, String],
            default: null
        },
        inputWidth: {
            type: Number,
            default: 200
        }
    },
    data () {
        return {
            imgField: this.inputField,
            imgUris: [],
            imgWidth: this.inputWidth || 200
        };
    },
    mounted () {
        this.fetchImages();
    },
    methods: {
        fetchImages () {
            if (!this.inputUrls || this.inputUrls === '') {
                this.imgUris.push(Constants.NO_IMAGE);
                return;
            }

            if (Array.isArray(this.inputUrls)) {
                for (let i = 0; i < this.inputUrls.length; i++) {
                    this.fetchSingleImage(this.inputUrls[i]);
                }
            }
            else {
                this.fetchSingleImage(this.inputUrls);
            }
        },
        fetchSingleImage (url) {
            this.$http.request({ url: url, method: 'GET', responseType: 'blob' })
                .then(res => {
                    if (res && res.data && `${res.data.type}`.includes('image/')) {
                        this.imgUris.push(URL.createObjectURL(res.data));
                    }
                    else {
                        this.imgUris.push(Constants.NO_IMAGE);
                    }
                })
                .catch(() => {
                    this.imgUris.push(Constants.NO_IMAGE);
                });
        }
    },
    computed: {
        imageStyle () {
            return {
                maxWidth: `${this.inputWidth}px`, // Set max-width dynamically based on the 'width' data property
                marginRight: '5px', // Optional padding between images
                flexShrink: '0', // Prevent image shrinking
            };
        },
    }
};
</script>

<style scoped>
.imgFit {
    max-width:500px;
    object-fit: cover; /* Ensures the image fits without distortion */
}
</style>
