<template>
    <div>
        <v-container v-if="!uploadBarcode" class="ma-0 pa-0">
            <v-container class="ma-0 pa-0">
                <v-row>
                    <v-col cols="12" md="12" sm="12" class="pa-0">
                        <v-card flat>
                            <v-card-text>
                                <!-- Text Field -->
                                <v-text-field v-model="answerValue"
                                    :prepend-icon="icon"
                                    :readonly="readonly"
                                    :prefix="prefix"
                                    :suffix="suffix"
                                    :placeholder="placeholder"
                                    :hint="hint"
                                    persistent-hint
                                    clearable
                                    clear-icon="mdi-close-circle"
                                    @input="emitValue"
                                    @keydown.enter.prevent="emitOnNextQuestion">
                                </v-text-field>

                                <!-- Format Selection -->
                                <v-row>
                                    <v-col v-if="scanOptions.EnableFormatSelect" cols="12" md="6">
                                        <v-combobox v-model="selectedScanType"
                                            :items="scanTypes"
                                            label="Format"
                                            @change="onChangeScanType">
                                        </v-combobox>
                                    </v-col>
                                </v-row>

                                <!-- Buttons for Scanning -->
                                <v-row justify="center" class="mt-3">
                                    <!-- Camera Scan -->
                                    <template v-if="scanOptions.EnableCamScan">
                                        <v-btn v-show="!isScanning"
                                            icon
                                            color="success"
                                            small
                                            @click="initBarcodeScanner">
                                            <v-icon>mdi-play-box-outline</v-icon>
                                        </v-btn>
                                        <v-btn v-show="isScanning"
                                            icon
                                            color="error"
                                            small
                                            @click="stopScanner">
                                            <v-icon>mdi-stop</v-icon>
                                        </v-btn>
                                    </template>

                                    <!-- Photo Scan -->
                                    <v-btn v-if="scanOptions.EnablePhotoScan"
                                        icon
                                        color="primary"
                                        small
                                        @click="onTakePicture">
                                        <v-icon>mdi-camera</v-icon>
                                    </v-btn>

                                    <!-- Upload Scan -->
                                    <v-btn v-if="scanOptions.EnableUploadScan"
                                        icon
                                        color="info"
                                        small
                                        @click="showUpload">
                                        <v-icon>mdi-upload</v-icon>
                                    </v-btn>
                                </v-row>

                                <!-- Error Message -->
                                <v-alert v-if="showNoBarcodeFound"
                                    type="error"
                                    dense
                                    class="mt-4">
                                    {{ error }}
                                </v-alert>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

            <v-row>
                <v-col sm="12">
                    <div v-show="isScanning && showQuagga" ref="quaggascanner" style="position: relative; width: 100%; height: 100%;">
                    </div>
                    <div v-show="isScanning && showZXing" style="position: relative; width: 100%; height: 100%;">
                        <StreamBarcodeReader
                            ref="scanner"
                            :autofocus="true"
                            :aspect-ratio="2"
                            @decode="(a, b, c) => onDecode(a, b, c)"
                            :full-width-barcode="fullWidthBarcode">
                        </StreamBarcodeReader>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <!-- Upload Section -->
            <v-row v-if="uploadBarcode" class="mt-4" v-show="!previewImage">
                <v-col cols="12" md="8">
                    <div id="image-scanner">
                        <!-- File Upload and Cancel Button -->
                        <v-row align="center">
                            <v-col cols="12" md="6">
                                <v-file-input v-model="file"
                                    prepend-icon="mdi-barcode"
                                    label="Upload Barcode"
                                    accept="image/*"
                                    capture="environment"
                                    @change="processImage"
                                    outlined>
                                </v-file-input>
                            </v-col>
                            <v-col cols="12" md="6" class="d-flex">
                                <v-btn small color="error" @click="cancelUpload">
                                    <v-icon left>mdi-cancel</v-icon>Cancel
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>

            <!-- Preview Section -->
            <div v-show="answerValue">
                <div v-if="previewImage" id="image-preview">
                    <v-row justify="center" class="mt-4">
                        <v-col cols="12" md="8">
                            <!-- Clear Button -->
                            <v-row justify="start" class="mb-3">
                                <v-btn small color="error" @click="clearPreview">
                                    <v-icon left>mdi-cancel</v-icon>Clear
                                </v-btn>
                            </v-row>

                            <!-- Image Preview -->
                            <v-row>
                                <v-col class="text-center">
                                    <img :src="previewImage"
                                        ref="imageElement"
                                        alt="Uploaded Image"
                                        style="max-width: 100%; border-radius: 8px;"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-container>

    </div>
</template>

<script>
import Quagga from 'quagga';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { BrowserMultiFormatReader } from '@zxing/library';
import StreamBarcodeReader from '@/controls/StreamBarcodeReader.vue';

export default {
    name: 'QuaggaScanner',
    emits: ['update-value', 'next-question', 'input'],
    props: {
        value: {
            type: String,
            required: true,
            default: '',
        },
        options: {
            type: Object,
            required: true,
            default: () => {
                const defaultValue = { EnableCamScan: true, EnablePhotoScan: true, EnableUploadScan: true, EnableFormatSelect: true };
                return defaultValue;
            },
            validator (value) {
                return (
                    typeof value.EnableCamScan === 'boolean' &&
                    typeof value.EnablePhotoScan === 'boolean' &&
                    typeof value.EnableUploadScan === 'boolean' &&
                    typeof value.EnableFormatSelect === 'boolean'
                );
            },
        },
        questionValue: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        prefix: {
            type: String,
            required: false,
        },
        suffix: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        hint: {
            type: String,
            required: false,
        },
        scantype: {
            type: String,
            required: false,
            default: 'None',
            validator (value) {
                return ['None',
                    'VIN',
                    'Engine Number',
                    'Registration Number',
                    'QR',
                    'Code 128',
                    'Code 39',
                    'Code39 VIN',
                    'EAN',
                    'EAN-extended',
                    'UPC',
                    'UPC-E',
                    'Codabar',
                    'I2of5',
                    'Standard 2 of 5',
                    'Code 39'].includes(value);
            },
        }
    },
    components: {
        StreamBarcodeReader
    },
    created () {},
    data () {
        return {
            error: 'No barcode detected',
            showNoBarcodeFound: false,
            selectedScanType: this.scantype,
            previousScanType: this.scantype,
            answerValue: this.value,
            isScanning: false,
            previewImage: null,
            file: null,
            uploadBarcode: false,
            fullWidthBarcode: false,
            showQuagga: false,
            showZXing: false,
            injectedElement: null,
            scanTypes: [
                'None',
                'VIN',
                'Engine Number',
                'Registration Number',
                'QR',
                'Code 128',
                'Code 39',
                'Code 39-VIN',
                'EAN 8',
                'EAN 13',
                'UPC',
                'UPC-E',
                'Codabar',
                'I2of5',
                'Standard 2 of 5'
            ],
            scanOptions: this.options
        };
    },
    methods: {
        getRenderSize () {
            let size = 12;
            size -= (this.scanOptions.EnableCamScan || this.scanOptions.EnablePhotoScan || this.scanOptions.EnableUploadScan) ? 3 : 0;
            size -= (this.scanOptions.EnableFormatSelect) ? 3 : 0;
            return size;
        },
        onChangeScanType () {
            this.stopScanner();
        },
        startZxing () {
            this.isScanning = true;
            this.showZXing = true;
            this.showQuagga = false;

            const scantype = (this.selectedScanType !== undefined && this.selectedScanType !== null) ? this.selectedScanType.toLowerCase().replace(' ', '') : '';
            if (scantype === 'vin' || scantype === 'enginenumber' || scantype === 'registrationnumber') {
                this.fullWidthBarcode = true;
            }
            else this.fullWidthBarcode = false;
            if (typeof this.answerValue === 'string') {
                this.answerValue = '';
            };
            this.$refs.scanner.startQRScanner();
            this.showScanButton = false;
            this.scannerRunning = true;
        },
        stopZxing () {
            if (!this.isScanning) return;
            this.isScanning = false;
            this.$refs.scanner.stopQRScanner();
            this.showScanButton = true;
            this.scannerRunning = false;
            this.showScanner = false;
            this.showZXing = false;
            this.showQuagga = false;
        },
        startQuagga () {
            this.showZXing = false;
            this.showQuagga = true;
            // in this case we would have to inject the div for quagga otherwise we will get an error
            const placeholder = this.$refs.quaggascanner;
            if (placeholder) {
                const newElement = document.createElement('div');
                newElement.id = 'barcode-scanner';
                placeholder.appendChild(newElement);
                this.injectedElement = newElement;
                Quagga.init(
                    {
                        numOfWorkers: 4,
                        willReadFrequently: true,
                        inputStream: {
                            name: 'Live',
                            type: 'LiveStream',
                            target: document.querySelector('#barcode-scanner'),
                        },
                        decoder: {
                            readers: this.barcodeReader, // Add all supported readers here
                        },
                        locator: {
                            patchSize: 'large', // Options: 'x-small', 'small', 'medium', 'large', 'x-large'
                            halfSample: false,
                        },
                        debug: {
                            drawBoundingBox: true, // Draw bounding box
                            showCanvas: true, // Show the canvas
                            showPatches: false, // Show detected patches
                            showFoundPatches: true, // Highlight found patches
                            drawScanline: true, // Draw scanning line
                            showPattern: true, // Show matching patterns
                        },
                    },
                    err => {
                        if (err) {
                            console.error(err);
                            return;
                        }
                        Quagga.start();
                    }
                );
                this.isScanning = true;
                Quagga.onDetected(result => {
                    if (!result || !result.codeResult || !result.codeResult.decodedCodes) {
                        this.showNoBarcodeFound = true;
                        return;
                    }
                    // Extract the decodedCodes array
                    const decodedCodes = result.codeResult.decodedCodes;
                    // Filter out invalid codes (e.g., where error is undefined)
                    const validCodes = decodedCodes.filter(code => typeof code.error !== 'undefined');
                    if (validCodes.length > 0) {
                        // Calculate the average error (lower error = higher confidence)
                        const averageError = validCodes.reduce((acc, code) => acc + code.error, 0) / validCodes.length;
                        // Define a threshold for high-confidence scans
                        const confidenceThreshold = 0.08; // Adjust based on testing
                        if (averageError < confidenceThreshold) {
                            this.answerValue = result.codeResult.code;
                            this.emitValue();
                            // Handle detected barcode
                            this.stopScanner();
                        }
                    }
                    else {
                        this.showNoBarcodeFound = true;
                    }
                });
                let scanLineY = 0; // Initial position of the scan line
                let direction = 1; // Direction: 1 = down, -1 = up
                Quagga.onProcessed(result => {
                    const drawingCtx = Quagga.canvas.ctx.overlay;
                    const drawingCanvas = Quagga.canvas.dom.overlay;
                    drawingCtx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);

                    if (result) {
                        if (result.boxes) {
                            drawingCtx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);
                            result.boxes
                                .filter(box => box !== undefined)
                                .forEach(box => {
                                    Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                                        color: 'green',
                                        lineWidth: 2,
                                    });
                                });
                        }

                        if (result.box) {
                            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                                color: 'blue',
                                lineWidth: 2,
                            });
                        }
                        // Draw scan line
                        const lineWidth = 2;
                        const lineColor = 'red';

                        // Move the scan line vertically
                        scanLineY += direction * 2; // Adjust speed by changing the increment

                        // Reverse direction if the line hits the canvas edges
                        if (scanLineY >= drawingCanvas.height || scanLineY <= 0) {
                            direction *= -1;
                        }

                        drawingCtx.beginPath();
                        drawingCtx.moveTo(0, scanLineY);
                        drawingCtx.lineTo(drawingCanvas.width, scanLineY);
                        drawingCtx.strokeStyle = lineColor;
                        drawingCtx.lineWidth = lineWidth;
                        drawingCtx.stroke();
                    }
                });
            }
        },
        stopQuagga () {
            if (!this.isScanning) return;
            if (Quagga !== null && Quagga !== undefined) {
                Quagga.stop(); // Stop the scanner
                this.isScanning = false; // Hide the scanner UI
                const scannerElement = document.querySelector('#barcode-scanner');
                if (scannerElement) {
                    scannerElement.innerHTML = ''; // Clear the scanner content
                }
                this.showZXing = false;
                this.showQuagga = false;
                // romve dynamic element
                const placeholder = this.$refs.placeholder;
                if (placeholder && this.injectedElement) {
                    // Remove the injected element
                    placeholder.removeChild(this.injectedElement);
                    // Clear the reference
                    this.injectedElement = null;
                }
            }
        },
        async onTakePicture () {
            this.clearPreview();
            const image = await Camera.getPhoto({
                quality: '90',
                width: 800,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Camera
            });
            const file = this.base64ToFile(image.base64String, 'image/png', 'photo.png');
            this.processImage(file);
        },
        base64ToFile (base64String, mimeType, fileName) {
            // Decode the Base64 string to binary data
            const byteCharacters = atob(base64String);

            // Create a byte array from the decoded string
            const byteArray = new Uint8Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteArray[i] = byteCharacters.charCodeAt(i);
            }

            // Create a Blob object from the byte array
            const blob = new Blob([byteArray], { type: mimeType });

            // Convert the Blob to a File object (optional)
            const file = new File([blob], fileName, { type: mimeType });

            return file;
        },
        cancelUpload () {
            this.uploadBarcode = false;
            this.clearPreview();
        },
        showUpload () {
            this.uploadBarcode = true;
            this.clearPreview();
        },
        clearPreview () {
            this.previewImage = null; // Clear the preview image
            this.file = null; // Reset file input
            this.answerValue = '';
        },
        parseSouthAfricanVIN (data) {
            const stringData = String(data);
            const vinRegex = /[A-HJ-NPR-Z0-9]{17}/; // Standard VIN regex
            const match = stringData.match(vinRegex);
            return match ? match[0] : null;
        },
        async decodeImage () {
            try {
                const codeReader = new BrowserMultiFormatReader();
                const imgElement = this.$refs.imageElement;
                this.$refs.imageElement.src = this.previewImage;
                const result = await codeReader.decodeFromImageElement(imgElement);
                const decodedData = result.getText();
                if (decodedData) {
                    const vinValues = decodedData.split('%');
                    if (vinValues !== undefined && vinValues.length > 0) {
                        const parsedVin = this.parseSouthAfricanVIN(decodedData);
                        switch (this.selectedScanType.toLowerCase().replace(' ', '')) {
                            case 'vin':
                                this.answerValue = parsedVin;
                                break;
                            case 'enginenumber':
                                this.answerValue = vinValues[13];
                                break;
                            case 'registrationnumber':
                                this.answerValue = vinValues[6];
                                break;
                            default:
                                this.answerValue = (this.answerValue === null || this.answerValue === '') ? ((parsedVin !== null) ? parsedVin : decodedData) : this.answerValue;
                                break;
                        }
                    }
                }
                else this.answerValue = decodedData;
                this.uploadBarcode = false;
                return true;
            }
            catch (error) {
                return false;
            }
        },
        onDecode (a, b, c) {
            this.answerValue = a;
            this.emitValue();
            this.stopScanner();
        },
        processImage (file) {
            this.answerValue = '';
            if (!file) {
                console.warn('No file selected.');
                return;
            }
            const reader = new FileReader();
            reader.onload = e => {
                this.previewImage = e.target.result; // Base64 image data
                // Analyze the image using Quagga
                Quagga.decodeSingle(
                    {
                        src: this.previewImage, // Base64 or image URL
                        numOfWorkers: 0, // No web workers for single image processing
                        inputStream: {
                            size: 800, // Resize the image for faster processing
                        },
                        decoder: {
                            readers: this.barcodeReader, // Add other readers as needed
                        },
                    },
                    async result => {
                        let nobCode = true;
                        if (result) {
                            if (result && result.codeResult) {
                                this.answerValue = result.codeResult.code;
                                this.uploadBarcode = false;
                                nobCode = false;
                                this.showNoBarcodeFound = false;
                            }
                            else {
                                this.showNoBarcodeFound = true;
                            }
                        }
                        else {
                            this.showNoBarcodeFound = true;
                        }
                        if (nobCode) {
                            this.showNoBarcodeFound = false;
                            const decodedOnce = await this.decodeImage();
                            if (!decodedOnce) {
                                const decodedTwice = await this.decodeImage();
                                if (!decodedTwice) {
                                    this.clearPreview();
                                    this.cancelUpload();
                                    this.showNoBarcodeFound = true;
                                }
                            }
                        }
                    }
                );
            };

            reader.readAsDataURL(file); // Read the file as a Base64 string
        },
        stopScanner () {
            const selectedScantype = (this.selectedScanType !== undefined && this.selectedScanType !== null) ? this.selectedScanType.toLowerCase().replace(' ', '') : '';
            const scantype = (this.previousScanType !== selectedScantype) ? this.previousScanType : selectedScantype;
            if (scantype === 'vin' || scantype === 'enginenumber' || scantype === 'registrationnumber' || scantype === 'qr') {
                this.stopZxing();
            }
            else {
                this.stopQuagga();
            }
        },
        initBarcodeScanner () {
            this.answerValue = '';
            this.clearPreview();
            const scantype = (this.selectedScanType !== undefined && this.selectedScanType !== null) ? this.selectedScanType.toLowerCase().replace(' ', '') : '';
            this.previousScanType = scantype;
            if (scantype === 'vin' || scantype === 'enginenumber' || scantype === 'registrationnumber' || scantype === 'qr') {
                this.startZxing();
            }
            else {
                this.startQuagga();
            }
        },
        emitOnNextQuestion () {
            this.emitValue();
            this.$emit('next-question', this.onNextQuestion);
        },
        emitValue () {
            this.$emit('update-value', this.answerValue);
            this.$emit('input', this.answerValue); // Emit to parent
        }
    },
    computed: {
        barcodeReader () {
            let returnval = [];
            const scantype = (this.selectedScanType !== undefined && this.selectedScanType !== null) ? this.selectedScanType.toLowerCase().replace(' ', '') : '';
            switch (scantype) {
                case 'code128':
                    returnval = ['code_128_reader'];
                    break;
                case 'code39':
                    returnval = ['code_39_reader'];
                    break;
                case 'code39-vin':
                    returnval = ['code_39_vin_reader'];
                    break;
                case 'ean13':
                    returnval = ['ean_reader'];
                    break;
                case 'ean8':
                    returnval = ['ean_8_reader'];
                    break;
                case 'upc':
                    returnval = ['upc_reader'];
                    break;
                case 'upc-e':
                    returnval = ['upc_e_reader'];
                    break;
                case 'i2of5':
                    returnval = ['i2of5_reader'];
                    break;
                case '2of5':
                    returnval = ['2of5_reader'];
                    break;
                case 'codabar':
                    returnval = ['codabar_reader'];
                    break;
                default:
                    returnval = [
                        'code_128_reader',
                        'code_39_reader',
                        'code_39_vin_reader',
                        'ean_reader',
                        'ean_8_reader',
                        'upc_reader',
                        'upc_e_reader',
                        'i2of5_reader',
                        '2of5_reader',
                        'codabar_reader'
                    ];
                    break;
            }
            return returnval;
        },
    },
    watch: {
        answerValue (val) {
            this.emitValue();
        },
        options (val) {
            this.scanOptions = val;
            this.clearPreview();
        },
        value (val) {
            this.answerValue = val;
        },
        selectedScanType (val, oldval) {
            this.previousScanType = oldval;
            this.selectedScanType = val;
        },
        scantype (val) {
            this.selectedScanType = val;
        },
    }
};
</script>

<style>
#barcode-scanner {
  z-index: 1; /* Ensure video is below */
  width:100%;
}

#barcode-scanner video{
    width:100%;
}
.drawingBuffer {
  z-index: 2; /* Ensure canvas is on top */
  pointer-events: none; /* Prevent interference with user interactions */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

</style>
